import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

import Seo from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faSyncAlt, faChevronLeft, faChevronRight, faFolderOpen } from "@fortawesome/free-solid-svg-icons"

const catTemplate = ({ data, location, pageContext }) => (
  <Layout>
    <Seo
      pagetitle={`CATEGORY: ${pageContext.catname}`}
      pagedesc={`「${pageContext.catname}」カテゴリーの記事です`}
      pagepath={location.pathname}
    />
    <section className="content bloglist">
      <div className="container">
        <h1 className="bar">CATEGORY: {pageContext.catname}</h1>
        <div className="posts">
          {data.allContentfulBlogPost.edges.map(({ node }) =>(
            <article className="post" key={node.id}>
              <Link to={`/blog/post/${node.slug}/`}>
                <figure>
                  <GatsbyImage
                    image={node.eyecatch.gatsbyImageData}
                    alt={node.eyecatch.description}
                  />
                </figure>
                <aside>
                  <time dateTime={node.updatedAt > node.publishDate ? node.updatedAt : node.publishDate}>
                    <FontAwesomeIcon icon={node.updatedAt > node.publishDate ? faSyncAlt : faClock} />
                    {node.updatedAt > node.publishDate ? node.updatedAtJP : node.publishDateJP}
                  </time>
                  <div className="cat">
                    <FontAwesomeIcon icon={faFolderOpen} />
                    <ul>
                      {node.category.map(cat => (
                        <li className={cat.categorySlug} key={cat.id}>{cat.category}</li>
                      ))}
                    </ul>
                  </div>
                </aside>
                <h3>{node.title}</h3>
              </Link>
            </article>
          ))}
        </div>

        <ul className="pagenation">
          {!pageContext.isFirst && (
            <li className="prev">
              <Link
                to={
                  pageContext.currentPage === 2
                    ? `/blog/cat/${pageContext.catslug}/`
                    : `/blog/cat/${pageContext.catslug}/${pageContext.currentPage - 1}/`
                } rel="prev"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>前のページ</span>
              </Link>
            </li>
          )}

          {!pageContext.isLast && (
            <li className="next">
              <Link to={`/blog/cat/${pageContext.catslug}/${pageContext.currentPage + 1}/`} rel="next">
                <span>次のページ</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </li>
          )}
        </ul>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query($catid: String!, $skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: {fields: publishDate, order: DESC}
      skip: $skip
      limit: $limit
      filter: { category: { elemMatch: { id: { eq: $catid } } } }
    ) {
      edges {
        node {
          title
          publishDateJP:publishDate(formatString: "YYYY年MM月DD日")
          publishDate
          updatedAtJP:updatedAt(formatString: "YYYY年MM月DD日")
          updatedAt
          category {
            category
            categorySlug
            id
          }
          id
          slug
          eyecatch {
            gatsbyImageData(layout: CONSTRAINED)
            description
          }
        }
      }
    }
  }
`

export default catTemplate